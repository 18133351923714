import styled from 'styled-components'

import { CREDIT_CARD_TYPE } from '@/utilities/constants'
import Text from '@/components/Text'

import mastercardIcon from '@/assets/icons/mastercard.svg'
import visaIcon from '@/assets/icons/visa.svg'

interface CreditCardProps {
  cardType?: string
  cardNumber: string
  exp: string
  reservedAmount: string
  reservedAmountLabel: string
}

const ImgContainer = styled.div`
  height: 1rem;
  width: 1.5rem;
  display: flex;
  background-color: ${({ theme }) => theme.colors.primary3};
  border-radius: 0.25rem;
  align-items: center;
  justify-content: center;
`

const Container = styled.div`
  flex: 1;
  min-height: 6.25rem;
`

const Inner = styled.div`
  display: flex;
  padding: 0.75rem;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.625rem;
  background-color: ${({ theme }) => theme.colors.gray};
  height: 100%;
`

const HiddenContainer = styled.div`
  display: flex;
  width: 1.5625rem;
  justify-content: space-between;
  margin-right: 0.375rem;

  & > span {
    height: 0.25rem;
    width: 0.25rem;
    border-radius: 0.25rem;
    background-color: ${({ theme }) => theme.colors.primary2};
  }
`

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const NumberContainer = styled.div`
  display: flex;
  align-items: center;
`

const ReservedAmountContainer = styled.div``

const CardImg = styled.img`
  width: 0.92rem;
`

const CreditCard = ({ cardType, cardNumber, reservedAmount, reservedAmountLabel }: CreditCardProps) => {
  const cardImgSrc = cardType === CREDIT_CARD_TYPE.MASTERCARD ? mastercardIcon : visaIcon

  return (
    <Container>
      <Inner>
        <ReservedAmountContainer>
          <Text type="heading">{reservedAmount}</Text>
          <Text type="pRegular" color="secondayBlur">
            {reservedAmountLabel}
          </Text>
        </ReservedAmountContainer>
        <InfoContainer>
          <ImgContainer>
            <CardImg src={cardImgSrc} />
          </ImgContainer>
          <NumberContainer>
            <HiddenContainer>
              <span />
              <span />
              <span />
              <span />
            </HiddenContainer>
            <Text type="pRegular">{cardNumber}</Text>
          </NumberContainer>
        </InfoContainer>
      </Inner>
    </Container>
  )
}

export default CreditCard

import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { useAppDispatch } from '@/utilities/store'
import { useGetPaymentAttemptStateQuery } from '@/slices/api'
import { resetPaymentAttemptId, setSessionId } from '@/slices/charging'
import PageLoader from '@/components/PageLoader'
import Text from '@/components/Text'
import UnderlineButton from '@/components/UnderlineButton'

const POLLING_INTERVAL = 2000
const CANCEL_AVAILABLE_TIMEOUT = 30000

const InfoContainer = styled.div`
  margin-top: 1.5rem;
  text-align: center;
`

const CancelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.5rem;
`

const SeparatorText = styled(Text).attrs({
  type: 'buttonRegular',
  color: 'placeholder',
})`
  margin: 0.25rem 0;
`

const ChargingStatus = () => {
  const [cancelAvailable, setCancelAvailable] = useState<boolean>(false)
  const { paymentAttemptId } = useParams()
  const { t } = useTranslation()

  const { data, error } = useGetPaymentAttemptStateQuery(paymentAttemptId!, { pollingInterval: POLLING_INTERVAL })

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (error) {
      dispatch(resetPaymentAttemptId())

      if ('status' in error) {
        const errorData = error.data as { chargingPointCode?: string; error?: string }
        const errorCode = errorData?.error ?? 'ErrorStatus500Message'

        if (errorData?.chargingPointCode) {
          navigate(`/charging/start/${errorData.chargingPointCode}?error=${errorCode}`, { replace: true })

          return
        }
      }

      navigate('/charging/point?error=ErrorStatus500Message', { replace: true })
    }

    if (data?.data?.sessionId) {
      dispatch(resetPaymentAttemptId())
      dispatch(setSessionId(data.data.sessionId))

      navigate(`/charging/session/${data.data.sessionId}`, { replace: true })
    }
  }, [navigate, error, data, dispatch])

  const handleCancel = () => {
    dispatch(resetPaymentAttemptId())
    navigate('/charging/point', { replace: true })
  }

  const handlePaymentRedirect = () => {
    if (!data?.data?.paymentUrl) {
      return
    }

    window.open(data.data.paymentUrl, '_self')
  }

  useEffect(() => {
    setTimeout(() => {
      setCancelAvailable(true)
    }, CANCEL_AVAILABLE_TIMEOUT)
  }, [])

  const LoaderInner = cancelAvailable ? (
    <InfoContainer>
      <Text type="buttonRegular">{t('pages:chargingStatus.infoLabel')}</Text>
      <CancelContainer>
        <UnderlineButton onClick={handleCancel}>{t('pages:chargingStatus.cancelButton')}</UnderlineButton>
        <SeparatorText>{t('pages:chargingStatus.separatorLabel')}</SeparatorText>
        <UnderlineButton onClick={handlePaymentRedirect}>{t('pages:chargingStatus.paymentButton')}</UnderlineButton>
      </CancelContainer>
    </InfoContainer>
  ) : null

  return <PageLoader>{LoaderInner}</PageLoader>
}

export default ChargingStatus

import { InputHTMLAttributes } from 'react'
import styled from 'styled-components'

import Text from '@/components/Text'

import checkMark from '@/assets/icons/check-mark.svg'

interface CheckBoxProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string
}

const Container = styled.label<{ $checked?: boolean }>`
  display: flex;
  flex: 1;
  cursor: pointer;

  & > input {
    opacity: 0;
    position: absolute;
    cursor: pointer;
  }
`

const CheckWrap = styled.span<{ $checked?: boolean }>`
  height: 1.125rem;
  width: 1.125rem;
  border-radius: 0.25rem;
  background-color: ${({ theme, $checked }) => theme.colors[$checked ? 'primary1' : 'primary3']};
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 0.0625rem ${({ theme, $checked }) => theme.colors[$checked ? 'primary1' : 'secondary1']};
  margin-right: 0.5rem;

  & > img {
    display: ${({ $checked }) => ($checked ? 'block' : 'none')};
  }
`
const CheckBox = ({ label, checked, ...props }: CheckBoxProps) => {
  return (
    <Container $checked={checked}>
      <CheckWrap $checked={checked}>
        <img src={checkMark} />
      </CheckWrap>
      <input {...props} type="checkbox" checked={checked} />
      <Text as="span" type="buttonMedium" color="secondayBlur">
        {label}
      </Text>
    </Container>
  )
}

export default CheckBox

import styled from 'styled-components'

import supportIcon from '@/assets/icons/support.svg'

const PHONE_NUMBER = '+37062247472'

const Container = styled.a`
  display: flex;
  position: absolute;
  top: 1rem;
  right: ${1 + 2.5 + 0.5}rem;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 2.5rem;
  background-color: ${({ theme }) => theme.colors.primary3}40;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.25rem 1rem rgba(23, 49, 89, 0.16);
`

const Support = () => {
  return (
    <Container href={`tel:${PHONE_NUMBER}`}>
      <img src={supportIcon} />
    </Container>
  )
}

export default Support

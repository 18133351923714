import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { ChargingStationPointResponse, AdHocStartPayload, ChargingSessionResponse } from '@/types/api'

export const baseUrl = import.meta.env.VITE_API_BASE_URL

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      headers.set('Accept', 'application/json')
      headers.set('Content-Type', 'application/json')

      return headers
    },
  }),
  endpoints: (builder) => ({
    getChargingStationPoint: builder.query<{ data: ChargingStationPointResponse }, string>({
      query: (code) => `/station/point/${code}`,
    }),
    startCharging: builder.mutation<
      { paymentAttemptId: string; paymentUrl: string; sessionId?: string },
      AdHocStartPayload
    >({
      query: (body) => ({ url: '/charging/ad-hoc/start', method: 'POST', body }),
    }),
    stopCharging: builder.mutation<object, string>({
      query: (sessionId) => ({ url: `/charging/stop/ad-hoc/${sessionId}`, method: 'POST' }),
    }),
    getChargingSession: builder.query<{ data: ChargingSessionResponse }, string>({
      query: (sessionId) => `/charging/session/ad-hoc/${sessionId}`,
    }),
    getPaymentAttemptState: builder.query<{ data?: { sessionId?: string; paymentUrl?: string } }, string>({
      query: (paymentAttemptId) => `/payment/ad-hoc/session/${paymentAttemptId}/state`,
    }),
  }),
})

export const {
  useLazyGetChargingStationPointQuery,
  useGetChargingStationPointQuery,
  useStartChargingMutation,
  useStopChargingMutation,
  useGetChargingSessionQuery,
  useGetPaymentAttemptStateQuery,
} = api

import styled from 'styled-components'

import Text from '@/components/Text'

export interface PartnerProps {
  imgSrc: string
  name: string
  href: string
}

const Container = styled.a`
  display: block;
  text-align: center;
  text-decoration: none;
`

const Icon = styled.img`
  display: block;
  height: 3.5rem;
  width: 3.5rem;
  margin: 0 auto 0.25rem auto;
`

const Partner = ({ imgSrc, name, href }: PartnerProps) => {
  return (
    <Container href={href} target="_blank">
      <Icon src={imgSrc} />
      <Text>{name}</Text>
    </Container>
  )
}

export default Partner

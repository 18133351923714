import { InputHTMLAttributes, forwardRef } from 'react'
import styled from 'styled-components'

import { Typography } from '@/utilities/theme'

import Text from '@/components/Text'

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  infoLabel?: string
  textAlignRight?: boolean
  fontType?: Typography
  error?: string
}

const Container = styled.div``

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const Input = styled.input<{ $textAlignRight?: boolean; $fontType: Typography; $hasError?: boolean }>`
  display: flex;
  width: 100%;
  border: solid 0.0625rem ${({ theme, $hasError }) => theme.colors[$hasError ? 'error' : 'secondary1']};
  border-radius: 0.625rem;
  padding: 0 1rem;
  margin-top: 0.5rem;
  height: 3.25rem;

  font-family: ${({ theme, $fontType }) => theme.typography[$fontType].fontFamily};
  font-size: ${({ theme, $fontType }) => theme.typography[$fontType].fontSize};
  font-weight: ${({ theme, $fontType }) => theme.typography[$fontType].fontWeight};
  text-align: ${({ $textAlignRight }) => ($textAlignRight ? 'right' : 'left')};

  color: ${({ theme }) => theme.colors.primary2};

  &:focus {
    outline-width: 0;
    border: solid 0.0625rem ${({ theme }) => theme.colors.primary1}73;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.placeholder};
  }
`

const ErrorText = styled(Text).attrs({ type: 'pRegular', color: 'error' })`
  margin-top: 0.5rem;
`

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ name, label, textAlignRight, fontType = 'buttonRegular', infoLabel, error, ...props }, ref) => {
    const id = 'input-' + name

    const AdditionalLabel = infoLabel ? (
      <Text type="pRegular" color="placeholder">
        {infoLabel}
      </Text>
    ) : null

    const Error = error ? <ErrorText>{error}</ErrorText> : null

    return (
      <Container>
        <LabelContainer>
          <Text type="label" as="label" {...{ htmlFor: id }}>
            {label}
          </Text>
          {AdditionalLabel}
        </LabelContainer>
        <Input
          name={name}
          id={id}
          $textAlignRight={textAlignRight}
          $fontType={fontType}
          $hasError={!!error}
          {...props}
          ref={ref}
        />
        {Error}
      </Container>
    )
  },
)

export default TextInput

import styled from 'styled-components'

const BG_WIDTH_VW = 132.8
const BG_OFFSET_VW = (BG_WIDTH_VW - 100) / 2

const Container = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.primary1};
  z-index: -1;
`

const RadialBackground = styled.div`
  width: ${BG_WIDTH_VW}vw;
  height: ${BG_WIDTH_VW}vw;
  background: radial-gradient(#ffd057 0%, ${({ theme }) => theme.colors.primary1} 80%);
  margin-left: -${BG_OFFSET_VW}vw;
`

const ImageBackground = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  background-image: url('/assets/icons/charging-background.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top -7.25rem left 0;
`

const Background = () => {
  return (
    <Container>
      <RadialBackground />
      <ImageBackground />
    </Container>
  )
}

export default Background

import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { useAppSelector } from '@/utilities/store'

export const useRequestError = () => {
  const [requestError, setRequestError] = useState<string | undefined>(undefined)

  const [searchParams] = useSearchParams()

  useEffect(() => {
    const errorParam = searchParams.get('error')

    if (!errorParam) {
      return
    }

    setRequestError(errorParam)
  }, [searchParams])

  return { requestError, setRequestError }
}

export const usePendingNavigation = () => {
  const paymentAttemptId = useAppSelector((state) => state.charging.paymentAttemptId)
  const sessionId = useAppSelector((state) => state.charging.sessionId)
  const isPending = !!paymentAttemptId || !!sessionId

  const navigate = useNavigate()

  useEffect(() => {
    if (!paymentAttemptId && !sessionId) {
      return
    }

    if (paymentAttemptId) {
      navigate(`/charging/status/${paymentAttemptId}`, { replace: true })
      return
    }

    if (sessionId) {
      navigate(`/charging/session/${sessionId}`, { replace: true })
    }
  }, [navigate, isPending, paymentAttemptId, sessionId])
}

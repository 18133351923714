import { useDispatch, useSelector } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'

import { api } from '@/slices/api'
import chargingReducer from '@/slices/charging'

const store = configureStore({
  reducer: {
    charging: chargingReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
})

export default store

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export const useAppSelector = useSelector.withTypes<RootState>()

export const useAppDispatch = useDispatch.withTypes<AppDispatch>()

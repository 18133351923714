import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import type { TFunction } from 'i18next'

import { ErrorResponse } from '@/types/api'

export const handleFetchResponseError = (error?: FetchBaseQueryError | SerializedError) => {
  if (!error) {
    return
  }

  if ('status' in error) {
    const data = error.data as ErrorResponse
    const code = data?.errors?.[0]?.code

    if (!code) {
      throw new Error('ErrorStatus500Message')
    }

    throw new Error(code)
  }

  throw new Error('ErrorStatus500Message')
}

export const getFormattedLicensePlate = (numberPlate: string) => {
  return numberPlate.replace(/\s/g, '').replace(/(\d)/, ' $1').toUpperCase()
}

export const getFieldError = (t: TFunction, ns: string, code?: string) => {
  if (!code) {
    return undefined
  }

  return t(ns + code)
}

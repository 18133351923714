import { ReactNode } from 'react'
import styled from 'styled-components'

import CheckBox from '@/components/CheckBox'
import Text from '@/components/Text'

interface InvoiceProps {
  children: ReactNode
  required?: boolean
  onChange: (value: boolean) => void
  label: string
  infoLabel: string
}

const Container = styled.div``

const InnerContainer = styled.div``

const InfoText = styled(Text).attrs({
  type: 'pRegular',
  color: 'placeholder',
})`
  margin: 0.25rem 0 1rem 1.625rem;
`

const Invoice = ({ children, required = false, onChange, label, infoLabel }: InvoiceProps) => {
  const handleCheck = () => {
    onChange(!required)
  }

  const Inner = required ? (
    <InnerContainer>
      <InfoText>{infoLabel}</InfoText>
      {children}
    </InnerContainer>
  ) : null

  return (
    <Container>
      <CheckBox label={label} checked={required} onChange={handleCheck} />
      {Inner}
    </Container>
  )
}

export default Invoice

import styled from 'styled-components'

import Text from '@/components/Text'

interface Item {
  label: string
  value: string
  highlighted?: boolean
}

interface InfoProps {
  items: Item[]
}

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.gray};
  padding: 1rem;
  border-radius: 0.5rem;
`

const ItemContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`

const Separator = styled.span`
  display: block;
  background-color: ${({ theme }) => theme.colors.secondary1};
  height: 0.0625rem;
  margin: 0.75rem 0;
  width: 100%;
`

const Info = ({ items }: InfoProps) => {
  const renderItem = (item: Item, index: number) => {
    const key = `info-item-${index}`
    const TopSeparator = index !== 0 ? <Separator /> : null

    const labelColor = item.highlighted ? 'primary1' : 'secondayBlur'
    const valueColor = item.highlighted ? 'primary1' : 'primary2'

    return (
      <div key={key}>
        {TopSeparator}
        <ItemContainer>
          <Text type="buttonRegular" color={labelColor}>
            {item.label}
          </Text>
          <Text type="label" color={valueColor}>
            {item.value}
          </Text>
        </ItemContainer>
      </div>
    )
  }

  return <Container>{items.map(renderItem)}</Container>
}

export default Info

import styled, { keyframes } from 'styled-components'

import { Color } from '@/utilities/theme'

interface LoaderProps {
  color?: Color
}

const loaderAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Container = styled.span<{ $color: Color }>`
  width: 2.25rem;
  height: 2.25rem;
  border: 0.234rem solid ${({ theme, $color }) => theme.colors[$color]};
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: ${loaderAnimation} 1s linear infinite;
`

const Loader = ({ color = 'primary3' }: LoaderProps) => {
  return <Container $color={color} />
}

export default Loader

import type { DefaultTheme } from 'styled-components'

export const FONT_FAMILY: string = 'Inter'

export const DEVICE_SIZES = {
  mobile: 550,
}

const typography: DefaultTheme['typography'] = {
  charging: {
    fontFamily: FONT_FAMILY,
    fontSize: '2.125rem',
    fontWeight: 500,
  },
  h1700: {
    fontFamily: FONT_FAMILY,
    fontSize: '1.8125rem',
    fontWeight: 700,
  },
  heading: {
    fontFamily: FONT_FAMILY,
    fontSize: '1.25rem',
    fontWeight: 600,
  },
  headingMedium: {
    fontFamily: FONT_FAMILY,
    fontSize: '1.25rem',
    fontWeight: 500,
  },
  h2700: {
    fontFamily: FONT_FAMILY,
    fontSize: '1.125rem',
    fontWeight: 700,
  },
  code: {
    fontFamily: FONT_FAMILY,
    fontSize: '1rem',
    fontWeight: 600,
  },
  codeMedium: {
    fontFamily: FONT_FAMILY,
    fontSize: '1rem',
    fontWeight: 500,
  },
  label: {
    fontFamily: FONT_FAMILY,
    fontSize: '0.875rem',
    fontWeight: 600,
  },
  button: {
    fontFamily: FONT_FAMILY,
    fontSize: '0.875rem',
    fontWeight: 700,
  },
  buttonMedium: {
    fontFamily: FONT_FAMILY,
    fontSize: '0.875rem',
    fontWeight: 500,
  },
  buttonRegular: {
    fontFamily: FONT_FAMILY,
    fontSize: '0.875rem',
    fontWeight: 400,
  },
  pBold: {
    fontFamily: FONT_FAMILY,
    fontSize: '0.75rem',
    fontWeight: 700,
  },
  p: {
    fontFamily: FONT_FAMILY,
    fontSize: '0.75rem',
    fontWeight: 500,
  },
  pRegular: {
    fontFamily: FONT_FAMILY,
    fontSize: '0.75rem',
    fontWeight: 400,
  },
}

const colors: DefaultTheme['colors'] = {
  primary1: '#FF6900',
  primary1Shadow: '#bd5002',
  primary2: '#1D1D1D',
  primary3: '#FFFFFF',
  primary3Opacity: 'rgba(255, 255, 255, 0.6)',
  primary3BigOpacity: 'rgba(255, 255, 255, 0.3)',
  secondary1: '#DAE0E4',
  secondary2: '#B4B4B4',
  secondary3: '#102D53',
  secondary4: '#919DAA',
  secondary5: '#F1F5F6',
  secondary6: '#505050',
  error: '#E90000',
  transparentBlack: 'rgba(16, 45, 83, 0.1)',
  alertOverlayBorder: '#B4B4B420',
  footer: '#F4F6F6',
  secondayBlur: '#6E6D72',
  gray: '#F2F5F6',
  placeholder: '#A3A5AB',
  separator: '#D9D9D9',
}

const queries: DefaultTheme['queries'] = {
  mobile: `(max-width: ${DEVICE_SIZES.mobile}px)`,
  desktop: `(min-width: ${DEVICE_SIZES.mobile}px)`,
}

const theme: DefaultTheme = {
  typography,
  colors,
  queries,
}

export type Typography = keyof DefaultTheme['typography']

export type Color = keyof DefaultTheme['colors']

export default theme

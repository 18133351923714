import i18n, { Resource } from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import en from '@/assets/translations/en.json'
import lt from '@/assets/translations/lt.json'
import lv from '@/assets/translations/lv.json'
import cs from '@/assets/translations/cs.json'
import pl from '@/assets/translations/pl.json'

export enum LANGUAGE {
  en = 'en',
  lt = 'lt',
  lv = 'lv',
  cs = 'cs',
  pl = 'pl',
}

const resources: { [key in LANGUAGE]: Resource } = {
  en,
  lt,
  lv,
  cs,
  pl,
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: LANGUAGE.en,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      lookupQuerystring: 'l',
    },
  })

export default i18n

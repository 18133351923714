import styled from 'styled-components'

import arrowLeft from '@/assets/icons/arrow-left.svg'

interface BackProps {
  onClick: () => void
}

const Container = styled.button`
  -webkit-appearance: none;
  text-align: center;
  cursor: pointer;
  border: none;

  display: flex;
  position: absolute;
  top: 1rem;
  left: 1rem;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 2.5rem;
  background-color: ${({ theme }) => theme.colors.primary3}40;
  box-shadow: 0 0.25rem 1rem rgba(23, 49, 89, 0.16);
  align-items: center;
  justify-content: center;
`

const Back = ({ onClick }: BackProps) => {
  return (
    <Container onClick={onClick}>
      <img src={arrowLeft} />
    </Container>
  )
}

export default Back

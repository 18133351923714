import styled from 'styled-components'

import Text from '@/components/Text'

interface ItemProps {
  heading: string
  subheading: string
  est?: boolean
}

const Container = styled.div`
  flex: 1;
  border: 0.0625rem solid ${({ theme }) => theme.colors.secondary1};
  border-radius: 0.625rem;
`

const Inner = styled.div`
  padding: 1.5rem 1rem;

  & > p {
    text-align: center;
  }
`

const Item = ({ heading, subheading, est }: ItemProps) => {
  const Estimated = est ? (
    <Text type="pRegular" color="placeholder">
      (Est. data)
    </Text>
  ) : null

  return (
    <Container>
      <Inner>
        <Text type="heading">{heading}</Text>
        <Text type="pRegular" color="placeholder">
          {subheading}
        </Text>
        {Estimated}
      </Inner>
    </Container>
  )
}

export default Item

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ChargingState {
  paymentAttemptId: string | null
  sessionId: string | null
}

const initialState: ChargingState = {
  paymentAttemptId: localStorage.getItem('paymentAttemptId'),
  sessionId: localStorage.getItem('sessionId'),
}

const chargingSlice = createSlice({
  name: 'charging',
  initialState,
  reducers: {
    setPaymentAttemptId(state, action: PayloadAction<string>) {
      state.paymentAttemptId = action.payload

      localStorage.setItem('paymentAttemptId', action.payload)
    },
    resetPaymentAttemptId(state) {
      state.paymentAttemptId = null

      localStorage.removeItem('paymentAttemptId')
    },
    setSessionId(state, action: PayloadAction<string>) {
      state.sessionId = action.payload

      localStorage.setItem('sessionId', action.payload)
    },
    resetSessionId(state) {
      state.sessionId = null

      localStorage.removeItem('sessionId')
    },
  },
})

export const { setPaymentAttemptId, resetPaymentAttemptId, setSessionId, resetSessionId } = chargingSlice.actions

export default chargingSlice.reducer

export const UNITS = {
  KWH: 'kWh',
  KM: 'km',
  MIN: 'min',
}

export const STATION_STATES = {
  AVAILABLE: 1,
  PLUGGED: 2,
  FAULTED: 3,
  RESERVED: 4,
  UNAVAILABLE: 5,
}

export const CHARGING_STATES = {
  INIT: 1,
  QUEUE: 2,
  ACTIVE: 3,
  FINISHED: 4,
  FINISHING: 5,
}

export const CREDIT_CARD_TYPE = {
  MASTERCARD: 'MASTERCARD',
  VISA: 'VISA',
}

import styled from 'styled-components'

import Text from '@/components/Text'
import Button from '@/components/Button'

interface EnergyProps {
  energyUsed: string
  chargingLimits: string
  onStopCharging: () => void
  loading?: boolean
  chargingLimitsLabel: string
  stopChargingButtonLabel: string
}

const Container = styled.div`
  display: flex;
  gap: 1rem;

  & > button {
    flex: 1;
  }
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Energy = ({
  energyUsed,
  chargingLimits,
  onStopCharging,
  loading,
  chargingLimitsLabel,
  stopChargingButtonLabel,
}: EnergyProps) => {
  return (
    <Container>
      <InfoContainer>
        <Text type="charging">{energyUsed}</Text>
        <p>
          <Text type="pRegular" color="placeholder" as="span">
            {chargingLimitsLabel}{' '}
          </Text>
          <Text type="pBold" color="placeholder" as="span">
            {chargingLimits}
          </Text>
        </p>
      </InfoContainer>
      <Button onClick={onStopCharging} loading={loading}>
        {stopChargingButtonLabel}
      </Button>
    </Container>
  )
}

export default Energy

import styled from 'styled-components'
import { DEVICE_SIZES } from '@/utilities/theme'

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 auto;
  padding: 0 1.5rem;

  @media ${({ theme }) => theme.queries.mobile} {
    width: 100%;
  }

  @media ${({ theme }) => theme.queries.desktop} {
    min-width: ${DEVICE_SIZES.mobile}px;
    max-width: ${DEVICE_SIZES.mobile}px;
  }
`

export default ContentContainer

import { useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { handleFetchResponseError } from '@/utilities/functions'
import { useRequestError, usePendingNavigation } from '@/utilities/hooks'
import { useLazyGetChargingStationPointQuery } from '@/slices/api'
import CodeInput from '@/components/CodeInput'
import Button from '@/components/Button'
import Text from '@/components/Text'

import chargingPointIcon from '@/assets/icons/charging-point.svg'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
`

const ContinueButton = styled(Button)`
  width: 100%;
`

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  align-items: center;
`

const Label = styled(Text).attrs({
  type: 'label',
})`
  margin: 2rem 0 0.5rem 0;
`

const ErrorText = styled(Text).attrs({ type: 'pRegular', color: 'error' })`
  margin-top: 0.375rem;
  text-align: center;
`

const ChargingPoint = () => {
  const [code, setCode] = useState<string>('')
  const { requestError, setRequestError } = useRequestError()

  const { t } = useTranslation()
  const navigate = useNavigate()

  const [getChargingStationPoint, { isLoading }] = useLazyGetChargingStationPointQuery()

  const InputError = requestError ? <ErrorText>{t('errors:' + requestError)}</ErrorText> : null

  const handleContinue = async () => {
    setRequestError('')

    if (!code) {
      setRequestError('fieldRequired')
      return
    }

    try {
      const response = await getChargingStationPoint(code)

      handleFetchResponseError(response.error)

      navigate(`/charging/start/${code}`)
    } catch (e) {
      const cError = e as Error
      setRequestError(cError.message)
    }
  }

  usePendingNavigation()

  return (
    <Container>
      <ContentWrap>
        <img src={chargingPointIcon} />
        <Label>{t('pages:chargingPoint.pointCodeLabel')}</Label>
        <CodeInput length={4} value={code} onChange={setCode} hasError={!!requestError} />
        {InputError}
      </ContentWrap>
      <ContinueButton onClick={handleContinue} loading={isLoading}>
        {t('pages:chargingPoint.continueButton')}
      </ContinueButton>
    </Container>
  )
}

export default ChargingPoint

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import logo from '@/assets/icons/logo.svg'
import appstoreDownload from '@/assets/icons/appstore-download.svg'
import googleplayDownload from '@/assets/icons/googleplay-download.svg'

import { DEVICE_SIZES } from '@/utilities/theme'
import PageContainer from '@/components/PageContainer'
import ContentContainer from '@/components/ContentContainer'
import Text from '@/components/Text'
import Footer from '@/components/Footer'
import LanguageSelect from '@/components/LanguageSelect'
import Button from '@/components/Button'

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 50%;
  background-image: url('/assets/icons/landing-background.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  z-index: -1;
`

const InnerContent = styled.div`
  margin-top: 8rem;
`

const DownloadContainer = styled.div`
  margin: 6rem 0 1.875rem 0;
`

const Heading = styled.span`
  text-align: center;
`

const Logo = styled.img`
  display: block;
  margin: 0 auto;
`

const LinksContainer = styled.div`
  margin-top: 1.875rem;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 1rem;

  a:first-child {
    justify-self: flex-end;
  }

  a:last-child {
    justify-self: flex-start;
  }

  img {
    max-width: 100%;
  }
`

const ChargingButtonContainer = styled.div`
  margin-top: 2rem;

  & > button {
    width: 100%;
    max-width: ${DEVICE_SIZES.mobile / 16}rem;
  }
`

const Landing = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const handleChargingNavigate = () => {
    navigate('/charging/point')
  }

  return (
    <PageContainer>
      <Background />
      <LanguageSelect />
      <ContentContainer>
        <InnerContent>
          <Logo src={logo} />
          <DownloadContainer>
            <Heading>
              <Text type="h1700" color="primary1" as="h1">
                {t('pages:landing.downloadHeading1')}
              </Text>
              <Text type="h1700" as="h1">
                {t('pages:landing.downloadHeading2')}
              </Text>
              <LinksContainer>
                <a href="https://itunes.apple.com/app/apple-store/id1529276995" target="_blank">
                  <img src={appstoreDownload} />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.inbalancegrid.app" target="_blank">
                  <img src={googleplayDownload} />
                </a>
              </LinksContainer>
              <ChargingButtonContainer>
                <Button styleType="white" onClick={handleChargingNavigate}>
                  {t('pages:landing.startAdhocButton')}
                </Button>
              </ChargingButtonContainer>
            </Heading>
          </DownloadContainer>
        </InnerContent>
      </ContentContainer>
      <Footer />
    </PageContainer>
  )
}

export default Landing

import { InputHTMLAttributes } from 'react'
import styled from 'styled-components'
import { ChangeHandler } from 'react-hook-form'

import TextInput from '@/components/TextInput'

import Price from './components/Price'

const Container = styled.div``

interface PriceInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  onChange: ChangeHandler
  error?: string
  pricePresets?: string[]
  infoLabel?: string
  symbol: string
}

const PricesContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
`

const PriceInput = ({ onChange, pricePresets = [], infoLabel, symbol, ...props }: PriceInputProps) => {
  const renderPrice = (value: string, index: number) => {
    const key = `${index}-${value}`

    return <Price value={value} key={key} onClick={() => onChange({ target: { value } })} symbol={symbol} />
  }

  const PricePresets = pricePresets.map(renderPrice)

  return (
    <Container>
      <TextInput {...props} onChange={onChange} textAlignRight fontType="code" infoLabel={infoLabel} />
      <PricesContainer>{PricePresets}</PricesContainer>
    </Container>
  )
}

export default PriceInput

import styled from 'styled-components'
import { useParams, Navigate, useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import { useGetChargingSessionQuery } from '@/slices/api'
import { resetSessionId } from '@/slices/charging'
import { getFormattedLicensePlate } from '@/utilities/functions'
import { CHARGING_STATES } from '@/utilities/constants'
import { useAppDispatch, useAppSelector } from '@/utilities/store'
import Text from '@/components/Text'
import Info from '@/components/Info'
import Button from '@/components/Button'
import PageLoader from '@/components/PageLoader'

import summaryIcon from '@/assets/icons/charging-summary.svg'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
`

const Label = styled(Text).attrs({
  type: 'pRegular',
  color: 'placeholder',
})`
  text-align: center;
`

const SummaryImg = styled.img.attrs({
  src: summaryIcon,
})`
  display: block;
  margin: 1.5rem auto 0.5rem auto;
`

const Heading = styled(Text).attrs({
  type: 'headingMedium',
})`
  text-align: center;
  margin-bottom: 0.5rem;
`

const InfoContainer = styled.div`
  margin: 1.5rem 0 2.5rem 0;
`

const StartButton = styled(Button)`
  width: 100%;
`

const ChargingSummary = () => {
  const { sessionId } = useParams()

  const { t } = useTranslation()
  const navigate = useNavigate()

  const { data, isLoading: isSessionLoading } = useGetChargingSessionQuery(sessionId!)

  const storedSessionId = useAppSelector((state) => state.charging.sessionId)
  const dispatch = useAppDispatch()

  const refNo = data?.data?.sessionId ?? ''

  const info = [
    {
      label: t('pages:chargingSummary.summaryInfo.licensePlateNumber'),
      value: data?.data?.userVehicle?.numberPlate
        ? getFormattedLicensePlate(data?.data?.userVehicle?.numberPlate)
        : '-',
    },
    {
      label: t('pages:chargingSummary.summaryInfo.startDate'),
      value: data?.data?.startedAt ? DateTime.fromISO(data.data.startedAt).toFormat('yyyy MM dd HH:mm') : '-',
    },
    {
      label: t('pages:chargingSummary.summaryInfo.endDate'),
      value: data?.data?.endedAt ? DateTime.fromISO(data.data.endedAt).toFormat('yyyy MM dd HH:mm') : '-',
    },
    {
      label: t('pages:chargingSummary.summaryInfo.duration'),
      value:
        data?.data?.startedAt && data?.data?.endedAt
          ? DateTime.fromISO(data.data.endedAt).diff(DateTime.fromISO(data.data.startedAt)).toFormat("h'h' mm'm'")
          : '-',
    },
    {
      label: t('pages:chargingSummary.summaryInfo.charged'),
      value: (data?.data?.energyUsed?.value ?? '0') + (data?.data?.energyUsed?.unit ?? ''),
    },
    {
      label: t('pages:chargingSummary.summaryInfo.price'),
      value: (data?.data?.price?.symbol ? data.data.price.symbol + ' ' : '') + (data?.data?.price?.value ?? '0'),
      highlighted: true,
    },
  ]

  const handleNewChargingSession = () => {
    if (sessionId === storedSessionId) {
      dispatch(resetSessionId())
    }
    navigate('/charging/point')
  }

  if (!data && isSessionLoading) {
    return <PageLoader />
  }

  if (data?.data.state !== CHARGING_STATES.FINISHED) {
    return <Navigate to={`/charging/session/${sessionId}`} replace />
  }

  return (
    <Container>
      <div>
        <Label>
          {t('pages:chargingSummary.refNoLabel')} {refNo}
        </Label>
        <SummaryImg />
        <Heading>{t('pages:chargingSummary.heading')}</Heading>
        <Label>{t('pages:chargingSummary.subheading')}</Label>
        <InfoContainer>
          <Info items={info} />
        </InfoContainer>
      </div>
      <StartButton onClick={handleNewChargingSession}>{t('pages:chargingSummary.startNewSessionButton')}</StartButton>
    </Container>
  )
}

export default ChargingSummary

import { useState, useRef, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { LANGUAGE } from '@/utilities/i18n'

import enFlag from '@/assets/icons/flags/en.svg'
import ltFlag from '@/assets/icons/flags/lt.svg'
import lvFlag from '@/assets/icons/flags/lv.svg'
import csFlag from '@/assets/icons/flags/cs.svg'
import plFlag from '@/assets/icons/flags/pl.svg'

import Item, { SIZE_IN_REM } from './components/Item'

interface Flag {
  language: LANGUAGE
  flagSrc: string
}

const FLAGS: Flag[] = [
  {
    language: LANGUAGE.en,
    flagSrc: enFlag,
  },
  {
    language: LANGUAGE.lt,
    flagSrc: ltFlag,
  },
  {
    language: LANGUAGE.lv,
    flagSrc: lvFlag,
  },
  {
    language: LANGUAGE.cs,
    flagSrc: csFlag,
  },
  {
    language: LANGUAGE.pl,
    flagSrc: plFlag,
  },
]

const NUMBER_OF_ITEMS = 5
const SPACING = 8
const SPACING_IN_REM = SPACING / 16
const TOTAL_HEIGHT_IN_REM = NUMBER_OF_ITEMS * SIZE_IN_REM + (NUMBER_OF_ITEMS - 1) * SPACING_IN_REM
const ITEM_SHADOW = '0 0.25rem 1rem rgba(23,49,89,0.16)'
const TRANSITION_DURATION = 0.2

const ItemContainer = styled.div`
  position: absolute;
  border-radius: ${SIZE_IN_REM / 2}rem;
  overflow: hidden;
  cursor: pointer;
`

const FirstItem = styled(ItemContainer)``

const SecondItem = styled(ItemContainer)``

const ThirdItem = styled(ItemContainer)``

const FourthItem = styled(ItemContainer)``

const FifthItem = styled(ItemContainer)``

const Container = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  flex-direction: column;
  width: ${SIZE_IN_REM}rem;
  height: ${SIZE_IN_REM}rem;
  justify-content: space-between;
  transition: ${TRANSITION_DURATION}s;

  ${FirstItem} {
    box-shadow: ${ITEM_SHADOW};
  }

  ${SecondItem} {
    top: 0;
    transition: ${TRANSITION_DURATION}s;
  }

  ${ThirdItem} {
    top: 0;
    transition: ${TRANSITION_DURATION}s;
  }

  ${FourthItem} {
    top: 0;
    transition: ${TRANSITION_DURATION}s;
  }

  ${FifthItem} {
    top: 0;
    transition: ${TRANSITION_DURATION}s;
  }

  &:hover {
    height: ${TOTAL_HEIGHT_IN_REM}rem;

    ${SecondItem} {
      top: ${SIZE_IN_REM + SPACING_IN_REM}rem;
      box-shadow: ${ITEM_SHADOW};
    }

    ${ThirdItem} {
      top: ${SIZE_IN_REM * 2 + SPACING_IN_REM * 2}rem;
      box-shadow: ${ITEM_SHADOW};
    }

    ${FourthItem} {
      top: ${SIZE_IN_REM * 3 + SPACING_IN_REM * 3}rem;
      box-shadow: ${ITEM_SHADOW};
    }

    ${FifthItem} {
      top: ${SIZE_IN_REM * 4 + SPACING_IN_REM * 4}rem;
      box-shadow: ${ITEM_SHADOW};
    }
  }
`

const LanguageSelect = () => {
  const [flags, setFlags] = useState<Flag[]>(FLAGS)
  const initial = useRef(true)

  const { i18n } = useTranslation()
  const language = i18n.language as LANGUAGE

  const handleItemShift = useCallback(
    (language: LANGUAGE) => {
      const indexOfLanguage = flags.findIndex((flag) => flag.language === language)

      if (indexOfLanguage === -1) {
        return
      }

      const flagsCopy = [...flags]
      flagsCopy.splice(indexOfLanguage, 1)

      setFlags([flags[indexOfLanguage], ...flagsCopy])
    },
    [flags],
  )

  const handleMouseLeave = () => {
    handleItemShift(language)
  }

  const handleSelect = (selectedLanguage: LANGUAGE) => {
    if (selectedLanguage === language) {
      return
    }

    i18n.changeLanguage(selectedLanguage)
  }

  useEffect(() => {
    if (!initial.current) {
      return
    }

    initial.current = false
    handleItemShift(language)
  }, [language, handleItemShift])

  return (
    <Container onMouseLeave={handleMouseLeave}>
      <FifthItem onClick={() => handleSelect(flags[4].language)}>
        <Item flagSrc={flags[4].flagSrc} />
      </FifthItem>
      <FourthItem onClick={() => handleSelect(flags[3].language)}>
        <Item flagSrc={flags[3].flagSrc} />
      </FourthItem>
      <ThirdItem onClick={() => handleSelect(flags[2].language)}>
        <Item flagSrc={flags[2].flagSrc} />
      </ThirdItem>
      <SecondItem onClick={() => handleSelect(flags[1].language)}>
        <Item flagSrc={flags[1].flagSrc} />
      </SecondItem>
      <FirstItem onClick={() => handleSelect(flags[0].language)}>
        <Item flagSrc={flags[0].flagSrc} />
      </FirstItem>
    </Container>
  )
}

export default LanguageSelect

import styled from 'styled-components'

interface ItemProps {
  flagSrc: string
}

const SIZE = 40
export const SIZE_IN_REM = SIZE / 16

const Container = styled.div`
  width: ${SIZE_IN_REM}rem;
  height: ${SIZE_IN_REM}rem;
  background-color: ${({ theme }) => theme.colors.primary3};
  display: flex;
  align-items: center;
  justify-content: center;
`

const Flag = styled.img``

const Item = ({ flagSrc }: ItemProps) => {
  return (
    <Container>
      <Flag src={flagSrc} />
    </Container>
  )
}

export default Item

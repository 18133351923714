import { useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { useForm, SubmitHandler, Controller, ChangeHandler } from 'react-hook-form'
import { useParams, Navigate, useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'

import { useAppDispatch } from '@/utilities/store'
import { useGetChargingStationPointQuery, useStartChargingMutation } from '@/slices/api'
import { setPaymentAttemptId, setSessionId } from '@/slices/charging'
import { UNITS } from '@/utilities/constants'
import { handleFetchResponseError, getFieldError } from '@/utilities/functions'
import { useRequestError, usePendingNavigation } from '@/utilities/hooks'
import PriceInput from '@/components/PriceInput'
import Button from '@/components/Button'
import Info from '@/components/Info'
import TextInput from '@/components/TextInput'
import PageLoader from '@/components/PageLoader'
import Text from '@/components/Text'

import Invoice from './components/Invoice'

type Inputs = {
  price: string
  licensePlate?: string
  invoiceRequired?: boolean
  invoiceCompanyName?: string
  invoiceCompanyCode?: string
  invoiceCompanyRegistrationCode?: string
  invoiceCompanyVATCode?: string
  invoiceEmail?: string
}

const POLLING_INTERVAL = 5000

const validationSchema: yup.ObjectSchema<Inputs> = yup.object({
  price: yup.string().required('fieldRequired'),
  licensePlate: yup.string(),
  invoiceRequired: yup.boolean(),
  invoiceCompanyName: yup.string().when('invoiceRequired', {
    is: true,
    then: (schema) => schema.required('fieldRequired'),
  }),
  invoiceCompanyCode: yup.string().when('invoiceRequired', {
    is: true,
    then: (schema) => schema.required('fieldRequired'),
  }),
  invoiceCompanyRegistrationCode: yup.string().when('invoiceRequired', {
    is: true,
    then: (schema) => schema.required('fieldRequired'),
  }),
  invoiceCompanyVATCode: yup.string().when('invoiceRequired', {
    is: true,
    then: (schema) => schema.optional(),
  }),
  invoiceEmail: yup.string().when('invoiceRequired', {
    is: true,
    then: (schema) => schema.required('fieldRequired'),
  }),
})

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const Form = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
`

const SubmitButton = styled(Button)`
  width: 100%;
  margin-top: 2.5rem;
`

const InputContainer = styled.div<{ $topMargin?: number; $bottomMargin?: number }>`
  margin: ${({ $topMargin, $bottomMargin }) => `${$topMargin ?? 0}rem 0 ${$bottomMargin ?? 1}rem 0`};
`

const ErrorText = styled(Text).attrs({ type: 'pRegular', color: 'error' })`
  margin-top: 1rem;
`

const ChargingStart = () => {
  const { requestError, setRequestError } = useRequestError()
  const { pointCode } = useParams()

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { t } = useTranslation()
  const {
    data,
    isLoading: isStationPointLoading,
    isError,
  } = useGetChargingStationPointQuery(pointCode!, { pollingInterval: POLLING_INTERVAL })
  const [startCharging, { isLoading: isStartLoading }] = useStartChargingMutation()

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<Inputs>({
    defaultValues: {
      price: '',
      invoiceRequired: false,
    },
    resolver: yupResolver(validationSchema),
  })

  const info = [
    {
      label: t('pages:chargingStart.pointInfo.location'),
      value: `${data?.data?.location?.street}, ${data?.data?.location?.city}`,
    },
    { label: t('pages:chargingStart.pointInfo.status'), value: t('states:station.' + data?.data?.state) },
    { label: t('pages:chargingStart.pointInfo.chargingType'), value: t('states:pointType.' + data?.data?.pointType) },
    {
      label: t('pages:chargingStart.pointInfo.chargingPrice'),
      value: `${data?.data?.asap?.value} ${data?.data?.asap?.symbol} / ${UNITS.KWH}`,
    },
  ]

  const isFreeCharging = !Number(data?.data?.asap?.value)

  const pricePresets = useMemo(() => {
    const chargingPrice = data?.data?.asap?.value

    if (!chargingPrice) {
      return []
    }

    if (isFreeCharging) {
      return ['0', '0', '0']
    }

    return [15, 50, 100].map((item) => {
      const preset = Math.ceil((Number(chargingPrice) * item) / 10) * 10

      if (preset < data?.data?.adHocPricing.minPrice) {
        return data?.data?.adHocPricing.minPrice.toString()
      }

      if (preset > data?.data?.adHocPricing.maxPrice) {
        return data?.data?.adHocPricing.maxPrice.toString()
      }

      return preset.toString()
    })
  }, [data?.data?.asap, data?.data?.adHocPricing, isFreeCharging])

  const priceSymbol = data?.data?.asap?.symbol ?? ''
  const priceInputLabel = t('pages:chargingStart.priceInput.label') + ' ' + priceSymbol

  useEffect(() => {
    if (!pricePresets.length) {
      return
    }

    setValue('price', pricePresets[1])
  }, [pricePresets, setValue])

  const handleFormSubmit: SubmitHandler<Inputs> = async (data) => {
    if (!pointCode) {
      return
    }

    const amount = data.price.replace(',', '.')
    try {
      const response = await startCharging({
        chargingPointCode: pointCode,
        price: Number(amount),
        plateNumber: data.licensePlate ? data.licensePlate.replace(' ', '').toUpperCase() : null,
        ...(data.invoiceRequired
          ? {
              companyDetail: {
                companyCode: data.invoiceCompanyCode!,
                companyName: data.invoiceCompanyName!,
                registrationAddress: data.invoiceCompanyRegistrationCode!,
                vatCode: data.invoiceCompanyRegistrationCode!,
              },
              companyEmail: data.invoiceEmail,
            }
          : { companyDetail: null }),
      })

      handleFetchResponseError(response.error)

      if (response.data?.sessionId) {
        dispatch(setSessionId(response.data.sessionId))

        navigate(`/charging/session/${response.data.sessionId}`, { replace: true })
        return
      }

      if (response.data?.paymentAttemptId) {
        dispatch(setPaymentAttemptId(response.data.paymentAttemptId))
      }

      if (typeof response.data?.paymentUrl === 'string') {
        window.open(response.data.paymentUrl, '_self')
      }
    } catch (e) {
      const cError = e as Error
      setRequestError(cError.message)
    }
  }

  const RequestError = requestError ? (
    <ErrorText>
      {t('errors:' + requestError, {
        min: `${data?.data?.adHocPricing.minPrice ?? '0'}${priceSymbol}`,
        max: `${data?.data?.adHocPricing.maxPrice ?? '0'}${priceSymbol}`,
      })}
    </ErrorText>
  ) : null

  const LicensePlate = data?.data?.freeParkingByNumberPlate ? (
    <InputContainer $topMargin={isFreeCharging ? 1.5 : 0} $bottomMargin={1.5}>
      <TextInput
        label={t('pages:chargingStart.licensePlateInput.label')}
        placeholder={t('pages:chargingStart.licensePlateInput.placeholder')}
        {...register('licensePlate')}
      />
    </InputContainer>
  ) : null

  const Price = !isFreeCharging ? (
    <InputContainer $topMargin={1.5} $bottomMargin={1.5}>
      <Controller
        control={control}
        name="price"
        render={({ field }) => (
          <PriceInput
            label={priceInputLabel}
            infoLabel={t('pages:chargingStart.priceInput.info')}
            name="price"
            value={field.value}
            onChange={field.onChange as ChangeHandler}
            pricePresets={pricePresets}
            error={getFieldError(t, 'errors:', errors.price?.message)}
            symbol={priceSymbol}
          />
        )}
      />
    </InputContainer>
  ) : null

  const InvoiceBlock = !isFreeCharging ? (
    <Controller
      control={control}
      name="invoiceRequired"
      render={({ field }) => (
        <Invoice
          required={field.value}
          onChange={field.onChange}
          label={t('pages:chargingStart.invoiceInput.label')}
          infoLabel={t('pages:chargingStart.invoiceInput.info')}
        >
          <InputContainer>
            <TextInput
              label={t('pages:chargingStart.invoiceCompanyNameInput.label')}
              {...register('invoiceCompanyName')}
              error={getFieldError(t, 'errors:', errors.invoiceCompanyName?.message)}
            />
          </InputContainer>
          <InputContainer>
            <TextInput
              label={t('pages:chargingStart.invoiceEmail.label')}
              {...register('invoiceEmail')}
              error={getFieldError(t, 'errors:', errors.invoiceEmail?.message)}
            />
          </InputContainer>
          <InputContainer>
            <TextInput
              label={t('pages:chargingStart.invoiceCompanyCodeInput.label')}
              {...register('invoiceCompanyCode')}
              error={getFieldError(t, 'errors:', errors.invoiceCompanyCode?.message)}
            />
          </InputContainer>
          <InputContainer>
            <TextInput
              label={t('pages:chargingStart.invoiceCompanyRegAddressInput.label')}
              {...register('invoiceCompanyRegistrationCode')}
              error={getFieldError(t, 'errors:', errors.invoiceCompanyRegistrationCode?.message)}
            />
          </InputContainer>
          <TextInput
            label={t('pages:chargingStart.invoiceCompanyVATCodeInput.label')}
            {...register('invoiceCompanyVATCode')}
            error={getFieldError(t, 'errors:', errors.invoiceCompanyVATCode?.message)}
          />
        </Invoice>
      )}
    />
  ) : null

  usePendingNavigation()

  if (isError || (!data && !isStationPointLoading)) {
    return <Navigate to="/charging/point" replace />
  }

  if (!data && isStationPointLoading) {
    return <PageLoader />
  }

  return (
    <Container>
      <Info items={info} />
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <div>
          {Price}
          {LicensePlate}
          {InvoiceBlock}
          {RequestError}
        </div>
        <SubmitButton type="submit" loading={isStartLoading}>
          {t('pages:chargingStart.startChargingButton')}
        </SubmitButton>
      </Form>
    </Container>
  )
}

export default ChargingStart

import VerificationInput, { VerificationInputProps } from 'react-verification-input'
import styled from 'styled-components'

interface CodeInputProps extends Pick<VerificationInputProps, 'length' | 'value'> {
  onChange: (value: string) => void
  hasError?: boolean
}

const Container = styled.div<{ $hasError?: boolean }>`
  .vi-container {
    width: 12rem;
    height: 4rem;
    gap: 0;
    align-items: center;
  }

  .vi-character {
    background-color: ${({ theme }) => theme.colors.primary3};
    display: inline-block;
    height: 3.5rem;
    width: 2.5rem;
    border-radius: 0.625rem;
    margin: 0 0.25rem;
    border-color: ${({ theme, $hasError }) => theme.colors[$hasError ? 'error' : 'secondary1']};
    font-family: ${({ theme }) => theme.typography.code.fontFamily};
    font-size: ${({ theme }) => theme.typography.code.fontSize};
    font-weight: ${({ theme }) => theme.typography.code.fontWeight};
    color: ${({ theme }) => theme.colors.primary2};
  }

  .vi-character--selected {
    border-color: ${({ theme, $hasError }) => ($hasError ? theme.colors.error : theme.colors.primary1 + '73')};
    outline: none;
    box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colors.primary1}1A;
  }

  .vi-character--inactive {
    background-color: ${({ theme }) => theme.colors.primary3};
  }
`

const CodeInput = ({ onChange, hasError, ...props }: CodeInputProps) => {
  const handleChange = (value: string) => {
    onChange(value)
  }

  return (
    <Container $hasError={hasError}>
      <VerificationInput
        {...props}
        onChange={handleChange}
        placeholder=""
        classNames={{
          container: 'vi-container',
          character: 'vi-character',
          characterInactive: 'vi-character--inactive',
          characterSelected: 'vi-character--selected',
          characterFilled: 'vi-character--filled',
        }}
      />
    </Container>
  )
}

export default CodeInput

import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom'

import Landing from './Landing'
import ChargingRoot from './ChargingRoot'
import ChargingPoint from './ChargingPoint'
import ChargingStart from './ChargingStart'
import ChargingSession from './ChargingSession'
import ChargingSummary from './ChargingSummary'
import ChargingStatus from './ChargingStatus'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Landing />,
  },
  {
    path: 'charging',
    element: <ChargingRoot />,
    children: [
      {
        index: true,
        element: <Navigate replace to="point" />,
      },
      {
        path: 'point',
        element: <ChargingPoint />,
      },
      {
        path: 'start/:pointCode',
        element: <ChargingStart />,
      },
      {
        path: 'session/:sessionId',
        element: <ChargingSession />,
      },
      {
        path: 'summary/:sessionId',
        element: <ChargingSummary />,
      },
      {
        path: 'status/:paymentAttemptId',
        element: <ChargingStatus />,
      },
    ],
  },
])

const Router = () => {
  return <RouterProvider router={router} />
}

export default Router

import { useCallback, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useParams, Navigate, useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import { useGetChargingSessionQuery, useStopChargingMutation } from '@/slices/api'
import { resetSessionId } from '@/slices/charging'
import { getFormattedLicensePlate, handleFetchResponseError } from '@/utilities/functions'
import { CHARGING_STATES } from '@/utilities/constants'
import { useAppDispatch } from '@/utilities/store'
import Text from '@/components/Text'
import CreditCard from '@/components/CreditCard'
import PageLoader from '@/components/PageLoader'

import Energy from './components/Energy'
import Item from './components/Item'

const POLLING_INTERVAL = 5000
const GRID_COLUMNS = 2
const GRID_GAP_REM = 1

const EnergyContainer = styled.div`
  margin: 2rem 0 1.5rem 0;
`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  & > div {
    flex-basis: calc(100% / ${GRID_COLUMNS} - ${GRID_GAP_REM}rem / ${GRID_COLUMNS} * (${GRID_COLUMNS} - 1));
    max-width: calc(100% / ${GRID_COLUMNS} - ${GRID_GAP_REM}rem / ${GRID_COLUMNS} * (${GRID_COLUMNS} - 1));
  }
`

const Row = styled.div`
  margin-bottom: 1rem;
`

const RefText = styled(Text).attrs({
  type: 'pRegular',
  color: 'placeholder',
})`
  text-align: center;
`

const ChargingSession = () => {
  const { sessionId } = useParams()
  const [sessionFinishing, setSessionFinishing] = useState<boolean>(false)

  const { t } = useTranslation()

  const {
    data,
    isError,
    isLoading: isSessionLoading,
  } = useGetChargingSessionQuery(sessionId!, { pollingInterval: POLLING_INTERVAL })
  const [stopCharging] = useStopChargingMutation()
  const isFreeCharging = !data?.data?.creditCard?.cardNumber
  const isSessionError = isError || (!data && !isSessionLoading)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const sessionInfo = {
    id: data?.data?.sessionId ?? '',
    currentPower: data?.data?.currentPower ? `${data?.data?.currentPower.value} ${data?.data?.currentPower.unit}` : '-',
    chargingLimits: `${data?.data?.assignedLimit ?? 0}A@${data?.data?.phasesUsed ?? 0}F`,
    licensePlate: {
      value: data?.data?.userVehicle?.numberPlate
        ? getFormattedLicensePlate(data?.data?.userVehicle?.numberPlate)
        : '-',
      label: t('pages:chargingSession.licensePlateNumberLabel'),
    },
    energyUsed: {
      value: data?.data?.estimation?.energyUsed.value ?? data?.data?.energyUsed?.value ?? '0',
      label:
        t('pages:chargingSession.energyUsedLabel') +
        ' ' +
        (data?.data?.estimation?.energyUsed.unit ?? data?.data?.energyUsed?.unit ?? ''),
    },
    distance: {
      value: data?.data?.estimation?.distance.value ?? data?.data?.distance?.value ?? '0',
      label:
        t('pages:chargingSession.distanceLabel') +
        ' ' +
        (data?.data?.estimation?.distance.unit ?? data?.data?.distance?.unit ?? ''),
    },
    stateOfCharge: {
      value: (data?.data?.stateOfCharge?.value.toString() ?? '0') + '%',
      label: t('pages:chargingSession.stateOfChargeLabel'),
    },
    duration: {
      value: data?.data?.startedAt
        ? DateTime.local().diff(DateTime.fromISO(data?.data?.startedAt)).toFormat('hh:mm')
        : '-',
      label: t('pages:chargingSession.durationLabel'),
    },
    price: {
      value: data?.data?.estimation?.price.value ?? data?.data?.price?.value ?? '0.00',
      label:
        t('pages:chargingSession.priceLabel') +
        ' ' +
        (data?.data?.estimation?.price.symbol ?? data?.data?.price?.symbol ?? ''),
    },
    creditCard: {
      cardType: data?.data?.creditCard?.cardType,
      cardNumber: data?.data?.creditCard?.cardNumber ?? '',
      exp: data?.data?.creditCard?.validUntil
        ? DateTime.fromISO(data.data.creditCard.validUntil).toFormat('MM/yy')
        : '-/-',
      reservedAmount: {
        value: data?.data?.reserved?.value ?? '',
        label:
          t('pages:chargingSession.reservedAmountLabel') +
          (data?.data?.reserved?.symbol ? ' ' + data.data.reserved.symbol : ''),
      },
    },
  }

  const handleStopCharging = useCallback(async () => {
    try {
      setSessionFinishing(true)
      const response = await stopCharging(sessionId!)
      handleFetchResponseError(response.error)
    } catch {
      return
    }
  }, [stopCharging, sessionId])

  useEffect(() => {
    if (!isSessionError) {
      return
    }

    dispatch(resetSessionId())
    navigate('/charging/point?error=ErrorStatus500Message', { replace: true })
  }, [isSessionError, dispatch, navigate])

  const LicensePlate = data?.data?.userVehicle ? (
    <Row>
      <Item heading={sessionInfo.licensePlate.value} subheading={sessionInfo.licensePlate.label} />
    </Row>
  ) : null

  const StateOfCharge = data?.data?.stateOfCharge?.value ? (
    <Item heading={sessionInfo.stateOfCharge.value} subheading={sessionInfo.stateOfCharge.label} />
  ) : null

  const ReservedAmount = !isFreeCharging ? (
    <CreditCard
      {...sessionInfo.creditCard}
      reservedAmount={sessionInfo.creditCard.reservedAmount.value}
      reservedAmountLabel={sessionInfo.creditCard.reservedAmount.label}
    />
  ) : null

  if (!data && isSessionLoading) {
    return <PageLoader />
  }

  if (data?.data.state === CHARGING_STATES.FINISHED) {
    return <Navigate to={`/charging/summary/${sessionId}`} replace />
  }

  return (
    <div>
      <RefText>
        {t('pages:chargingSession.refNoLabel')} {sessionInfo.id}
      </RefText>
      <EnergyContainer>
        <Energy
          energyUsed={sessionInfo.currentPower}
          chargingLimits={sessionInfo.chargingLimits}
          onStopCharging={handleStopCharging}
          loading={sessionFinishing}
          chargingLimitsLabel={t('pages:chargingSession.chargingLimitsLabel')}
          stopChargingButtonLabel={t('pages:chargingSession.stopChargingButton')}
        />
      </EnergyContainer>
      {LicensePlate}
      <Grid>
        <Item
          heading={sessionInfo.energyUsed.value}
          subheading={sessionInfo.energyUsed.label}
          est={!!data?.data?.estimation?.energyUsed}
        />
        <Item
          heading={sessionInfo.distance.value}
          subheading={sessionInfo.distance.label}
          est={!!data?.data?.estimation?.distance}
        />
        {StateOfCharge}
        <Item heading={sessionInfo.duration.value} subheading={sessionInfo.duration.label} />
        <Item
          heading={sessionInfo.price.value}
          subheading={sessionInfo.price.label}
          est={!!data?.data?.estimation?.price}
        />
        {ReservedAmount}
      </Grid>
    </div>
  )
}

export default ChargingSession

import React from 'react'
import ReactDOM from 'react-dom/client'
import { ThemeProvider } from 'styled-components'
import { Provider as ReduxProvider } from 'react-redux'

import './styles/fonts.css'
import './styles/reset.css'
import '@/utilities/sentry'
import '@/utilities/i18n'

import Router from '@/pages'
import theme from '@/utilities/theme'
import store from '@/utilities/store'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    </ReduxProvider>
  </React.StrictMode>,
)

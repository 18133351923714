import { ReactNode } from 'react'
import styled from 'styled-components'

import Loader from '@/components/Loader'

interface PageLoaderProps {
  children?: ReactNode
}

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const PageLoader = ({ children }: PageLoaderProps) => {
  return (
    <Container>
      <Loader color="primary1" />
      {children}
    </Container>
  )
}

export default PageLoader

import styled from 'styled-components'
import { Typography, Color } from '@/utilities/theme'

interface TextProps {
  type?: Typography
  color?: Color
  as?: keyof HTMLElementTagNameMap
  children: string | string[]
}

const Container = styled.div<{ $type: Typography; $color: Color }>`
  font-family: ${({ theme, $type }) => theme.typography[$type].fontFamily};
  font-size: ${({ theme, $type }) => theme.typography[$type].fontSize};
  font-weight: ${({ theme, $type }) => theme.typography[$type].fontWeight};
  color: ${({ theme, $color }) => theme.colors[$color]};
`

const Text = ({ type = 'p', color = 'primary2', as = 'p', ...props }: TextProps) => {
  return <Container as={as} $type={type} $color={color} {...props} />
}

export default Text

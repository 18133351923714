import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Text from '@/components/Text'
import Partner, { PartnerProps } from './components/Partner'

import uniparkLogo from '@/assets/icons/partners/unipark.svg'
import mobillyLogo from '@/assets/icons/partners/mobilly.svg'
import caroneLogo from '@/assets/icons/partners/carone.svg'
import snabbLogo from '@/assets/icons/partners/snabb.svg'

const PARTNERS: PartnerProps[] = [
  {
    name: 'UniPark',
    imgSrc: uniparkLogo,
    href: 'https://unipark.lt/',
  },
  {
    name: 'Mobilly',
    imgSrc: mobillyLogo,
    href: 'https://mobilly.lv/',
  },
  {
    name: 'CarOne',
    imgSrc: caroneLogo,
    href: 'https://www.carone.app/',
  },
  {
    name: 'Snabb',
    imgSrc: snabbLogo,
    href: 'https://snabb.xyz/',
  },
]

const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.footer};
  padding: 2rem 0;
  border-radius: 1.25rem 1.25rem 0 0;
`

const Heading = styled(Text)`
  text-align: center;
`

const Copyright = styled(Text)`
  text-align: center;
`

const PartnersContainer = styled.div`
  overflow: auto;
  white-space: nowrap;
  max-width: fit-content;
  margin: 1.5rem auto 2rem auto;
`

const PartnerContainer = styled.div`
  display: inline-block;
  margin-left: 1.5rem;
  padding-bottom: 0.3125rem;

  &:last-child {
    margin-right: 1.5rem;
  }
`

const Footer = () => {
  const { t } = useTranslation()

  const renderPartner = (item: PartnerProps, index: number) => {
    const key = `partner-item-${index}`

    return (
      <PartnerContainer key={key}>
        <Partner {...item} />
      </PartnerContainer>
    )
  }
  return (
    <Container>
      <Heading type="h2700">{t('pages:landing.footer.heading')}</Heading>
      <PartnersContainer>{PARTNERS.map(renderPartner)}</PartnersContainer>
      <Copyright>&copy; 2024 {t('pages:landing.footer.copyright')}</Copyright>
    </Container>
  )
}

export default Footer

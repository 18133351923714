import { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import PageContainer from '@/components/PageContainer'
import ContentContainer from '@/components/ContentContainer'
import LanguageSelect from '@/components/LanguageSelect'
import Support from '@/components/Support'

import Back from './components/Back'
import Background from './components/Background'

import logo from '@/assets/icons/logo-white.svg'

const PATHS_WITH_BACK_BTN = ['/charging/point', '/charging/start']

const StyledPageContainer = styled(PageContainer)``

const Header = styled.div`
  display: flex;
  height: 10rem;
  flex-direction: column;
  justify-content: flex-end;

  & > img {
    align-self: center;
    margin-bottom: 2rem;
  }
`

const ContentWrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.primary3};
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
  padding: 2rem 0;
`

const ChargingRoot = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const visibleBackButton = useMemo(() => PATHS_WITH_BACK_BTN.some((item) => pathname.includes(item)), [pathname])

  const handleBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const BackButton = visibleBackButton ? <Back onClick={handleBack} /> : null

  return (
    <StyledPageContainer>
      <Background />
      {BackButton}
      <Support />
      <LanguageSelect />
      <Header>
        <img src={logo} />
      </Header>
      <ContentWrap>
        <ContentContainer>
          <Outlet />
        </ContentContainer>
      </ContentWrap>
    </StyledPageContainer>
  )
}

export default ChargingRoot

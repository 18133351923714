import { ButtonHTMLAttributes } from 'react'
import styled from 'styled-components'

import Loader from '@/components/Loader'

type StyleType = 'white' | 'primary'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  styleType?: StyleType
  loading?: boolean
}

const Container = styled.button<{ $styleType: StyleType }>`
  -webkit-appearance: none;
  border-radius: 0;
  text-align: center;
  box-shadow: none;
  height: 3.5rem;
  cursor: pointer;
  border: solid 0.125rem ${({ theme, $styleType }) => theme.colors[$styleType === 'white' ? 'secondary5' : 'primary1']};
  border-radius: 1.75rem;
  color: ${({ theme, $styleType }) => theme.colors[$styleType === 'white' ? 'primary2' : 'primary3']};
  background-color: ${({ theme, $styleType }) => theme.colors[$styleType === 'white' ? 'primary3' : 'primary1']};

  font-family: ${({ theme }) => theme.typography.button.fontFamily};
  font-size: ${({ theme }) => theme.typography.button.fontSize}rem;
  font-weight: ${({ theme }) => theme.typography.button.fontWeight};
`

const Button = ({ styleType = 'primary', loading, children, ...props }: ButtonProps) => {
  const Inner = loading ? <Loader /> : children

  return (
    <Container {...props} $styleType={styleType} disabled={!!loading}>
      {Inner}
    </Container>
  )
}

export default Button

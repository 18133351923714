import { ButtonHTMLAttributes } from 'react'
import styled from 'styled-components'

const Button = styled.button`
  -webkit-appearance: none;
  border-radius: 0;
  text-align: center;
  box-shadow: none;
  cursor: pointer;
  border: none;
  background: none;
  color: ${({ theme }) => theme.colors.primary1};
  text-decoration: underline;
  text-underline-offset: 0.187rem;

  font-family: ${({ theme }) => theme.typography.buttonRegular.fontFamily};
  font-size: ${({ theme }) => theme.typography.buttonRegular.fontSize}rem;
  font-weight: ${({ theme }) => theme.typography.buttonRegular.fontWeight};
`

const UnderlineButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return <Button {...props} />
}

export default UnderlineButton

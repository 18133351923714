import { ButtonHTMLAttributes } from 'react'
import styled from 'styled-components'
import Text from '@/components/Text'

interface PriceProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  value: string
  symbol: string
}

const Container = styled.button`
  -webkit-appearance: none;
  border-radius: 0;
  text-align: inherit;
  box-shadow: none;
  padding: 0.6875rem 1rem;
  cursor: pointer;
  border: none;

  display: flex;
  flex: 1;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.gray};
  justify-content: center;
`

const Price = ({ value, symbol, ...props }: PriceProps) => {
  return (
    <Container {...props} type="button">
      <Text as="span" type="buttonMedium">
        {`${value} ${symbol}`}
      </Text>
    </Container>
  )
}

export default Price
